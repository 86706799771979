
$(document).ready(function() {
    $('.photos').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });
    $(function () {
         $("input,select,textarea").not("[type=submit]").jqBootstrapValidation();
     } );


    /* START menu for tablets and mobiles */
    $("#head a.mobile-btn").click(function() {
        if($('#head .nav').hasClass('active')){
            $('#head .nav').removeClass('active');
            $(this).removeClass('active');
            $(this).find('i.icon').addClass('icon-menu').removeClass('icon-close');
        }else{
            $('#head .nav').addClass('active');
            $(this).addClass('active');
            $(this).find('i.icon').removeClass('icon-menu').addClass('icon-close');
        }
    });

    $('a.close-modal').click(function() {
        $('#moda').removeClass('active');
    });
});
